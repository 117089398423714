<template>
  <div class="tabs tabs-team tabs-share-wrapper clearfix">
    <ul class="tab-nav clearfix">
      <li
        class="rounded-full cursor-pointer"
        v-on:click="toggleTabs(1)"
        v-bind:class="{
          'bg-transparent': openTab !== 1,
          'bg-main text-secondary': openTab === 1,
        }"
      >
        Description
      </li>
      <li
        class="rounded-full cursor-pointer"
        v-on:click="toggleTabs(2)"
        v-bind:class="{
          'bg-transparent': openTab !== 2,
          'bg-main text-secondary': openTab === 2,
        }"
        v-if="videoSource !== ''"
      >
        Video
      </li>
      <li
        class="rounded-full cursor-pointer"
        v-on:click="toggleTabs(3)"
        v-bind:class="{
          'bg-transparent': openTab !== 3,
          'bg-main text-secondary': openTab === 3,
        }"
        v-if="imageCount > 1"
      >
        Gallery
      </li>
      <li
        class="rounded-full cursor-pointer"
        v-on:click="toggleTabs(4)"
        v-bind:class="{
          'bg-transparent': openTab !== 4,
          'bg-main text-secondary': openTab === 4,
        }"
      >
        Comments
      </li>
    </ul>
    <div class="container">
      <div class="tab-container">
        <div
          class="tab-content clearfix"
          v-bind:class="{
            hidden: openTab !== 1,
            block: openTab === 1,
          }"
        >
          <div v-html="myStory"></div>
          <p class="show-more-button cursor-pointer" @click="longRead" v-if="readmoreShow">
            <span v-if="readmore === false">Continue reading</span>
            <span v-else>Collapse</span>
          </p>
          <p class="show-more-button cursor-pointer" v-if="0"></p>
        </div>
        <div
          class="tab-content clearfix"
          v-bind:class="{
            hidden: openTab !== 2,
            block: openTab === 2,
          }"
        >
          <div class="video-thumbnail-wrapper" v-if="videoSource !== ''">
            <iframe
              width="100%"
              height="409"
              :src="videoSource"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write;
            encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
        <div
          class="tab-content clearfix"
          v-bind:class="{
            hidden: openTab !== 3,
            block: openTab === 3,
          }"
        >
          <div class="row" v-if="imageCount > 1">
            <div class="col-md-4" v-for="n in imagelist" :key="n">
              <a href="" data-lightbox="image" class="gallery-item">
                <img :src="n.src" alt="Single Image" />
                <div class="overlay">
                  <div class="overlay-wrap">
                    <i class="icon-line-zoom-in"></i>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div
          class="tab-content clearfix"
          v-bind:class="{
            hidden: openTab !== 4,
            block: openTab === 4,
          }"
        >
          <div class="comment-plugin-wrap">
            <div>
              <Disqus shortname="cngdev" :pageConfig="pageConfig" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Disqus } from "vue-disqus";

export default {
  components: {
    Disqus,
  },
  data() {
    return {
      openTab: 1,
      readmore: false,
      readmoreShow: false,
      myStory: "",
    };
  },
  async created() {
    if (this.story) {
      this.storyShow();
    } else {
      // start watching this prop
      const unwatch = this.$watch("story", () => {
        this.storyShow();
        unwatch();
      });
    }
  },
  methods: {
    toggleTabs(tabNumber) {
      this.openTab = tabNumber;
    },
    longRead() {
      if(this.readmore){
        this.myStory = this.story.substring(0, 500) + ".....";
      }else{
        this.myStory = this.story;
      }
      this.readmore = !this.readmore;
    },
    storyShow(){
      if (parseInt(this.story.length) > 500) {
        this.readmoreShow = true;
        this.myStory = this.story.substring(0, 500) + ".....";
      } else {
        this.myStory = this.story;        
      }
    }
  },
  props: {
    story: {
      default: "",
      type: Text,
    },
    videoSource: {
      default: "",
      type: Text,
    },
    imagelist: {
      default: [],
    },
    imageCount: {
      default: 0,
    },
    campaign_name: {default: ""},
    pageConfig: {},
  },
};
</script>
<style scoped>
a.gallery-item > img {
  height: 350px;
}
@media(max-width: 767px){
  .tabs.tabs-share-wrapper ul.tab-nav{padding: 5px 0 !important;}
  .tabs.tabs-team ul.tab-nav{ margin-bottom: 5px;}
}
</style>
